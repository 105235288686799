import React from 'react';

export default function Img({ srcImg, altImg, classNameImg }) {
  return <img src={srcImg} alt={altImg} className={classNameImg} />;
}

Img.defaultProps = {
  srcImg: '',
  altImg: '',
  classNameImg: '',
};
