import React, { useEffect, useState } from 'react';
import './style.css';
import ContentLayout from '../../../components/organisms/Shared/ContentLayout';
import Table from '../../../components/organisms/Shared/Table';

const AbsensiGuru = ({ teacherData }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(teacherData);
  }, [teacherData]);

  const tableDetails = {
    isNo: true,
    fields: [
      {
        title: 'NAMA',
        fieldName: 'username',
      },
    ],
    data: data,
    actions: {
      isAction: true,
      isDetail: false,
      isEdit: false,
      isDelete: false,
      isPagination: false,
      isVerify: true,
      isInfo: true,
      isDel: true,
      isSubAction: true,
    },
    attributes: {
      isAdd: true,
      isSearch: true,
      isPagination: true,
      isEdits: false,
    },
  };
  return (
    <ContentLayout>
      <Table tableDetails={tableDetails} itemsPerPage={5} title={'Action'}/>
    </ContentLayout>
  );
};

export default AbsensiGuru;
