import React, { useEffect, useState } from 'react'

import { letters } from '../../../services/letters';
import { Checkbox, CircularProgress } from '@material-ui/core';
import { Favorite, FavoriteBorder,Mail } from '@mui/icons-material';

export default function List() {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await letters.services.get.getLetterData();
        const slicedData = result.slice(0, 10);
        setData(slicedData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setData([]);
      }
    }

    fetchData();
    return () => {
      setData(null);
    };
  }, []);
  return (
    <div>
      {data ? (
        <div>
          {data.map((item) => (
            <div key={item.no} className='flex mt-2 gap-5 items-center border-b-2 p-2 mx-2 border-black'>
              <Checkbox color='default' />
              <Mail />
              <Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />} />
              <div className="font-bold w-[250px] border-r-2 border-black pl-2">
                <h1>{item.user}</h1>
              </div>
              <div className="font-medium w-full border-r-2 border-black">
                <h1>{item.body}</h1>
              </div>
              <div className="w-[200px] text-end justify-end pr-2">
                <p>{'timeList'}</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="p-2 m-2 py-4 text-center justify-center w-full h-full">
          <CircularProgress />
        </div>
      )}
    </div>
  )
}

