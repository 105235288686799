import React, { useState } from 'react';
import ButtonIcon from '../../../molecules/ButtonIcon';
import Button from '../../../atoms/Button';

function Modal({ title, classNameModal }) {
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const handleSave = () => {
        // Logika untuk menyimpan data dari modal
        console.log('Data tersimpan!');
        // Setelah data disimpan, tutup modal
        setIsOpen(false);
    };

    return (
        <div>
            <Button onClick={openModal} className={classNameModal}>
                {title}
            </Button>

            {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="modal-overlay fixed inset-0 bg-gray-500 opacity-50"></div>
                    <div className="modal-container bg-white w-1/2 p-6 rounded-lg z-50 relative">
                        <div className="flex items-center justify-center">
                            <div className="w-full p-2">
                                <p className="text-lg font-bold">Modal Title</p>
                            </div>
                            <div className="justify-end p-2">
                                <ButtonIcon classNameDiv={'btn-unactive'} onClickDiv={closeModal} titleButton={'X'} />
                            </div>
                        </div>
                        <div className="modal-content p-2">
                            <p>Isi modal di sini...</p>
                            <div className="flex justify-end mt-4">
                                <button onClick={handleSave} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2">
                                    Simpan
                                </button>
                                <button onClick={closeModal} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded">
                                    Tutup
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Modal;
