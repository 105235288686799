import React, { useState, useEffect } from 'react';
import './style.css';
import ContentLayout from '../../../components/organisms/Shared/ContentLayout';
import Table from '../../../components/organisms/Shared/Table';

const DataGuru = ({ teacherData }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(teacherData);
  }, [teacherData]);

  const tableDetails = {
    //nomor urut
    isNo: true,
    //field utama yang akan ditampilkan
    fields: [
      {
        title: 'NAMA',
        fieldName: 'username',
      },
      {
        title: 'ADDRESS',
        fieldName: 'body',
      },
    ],
    //data yang didapakan dari API
    data: data,
    //apakah akan menampilkan action
    actions: {
      isAction: true,
      isDetail: true,
      isEdit: false,
      isDelete: true,
      isPagination: false,
    },
    //apakah akan menampilkan attribute
    attributes: {
      isAdd: true,
      isSearch: true,
      isPagination: true,
    },
    pathname: '/guru/data-guru/detail-guru'
  };

  return (
    <ContentLayout>
      <Table tableDetails={tableDetails} itemsPerPage={7} title={'Action'} />
    </ContentLayout>
  );
};

export default DataGuru;
