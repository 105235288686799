import React from 'react';

export const TableWrapper = ({ children, className }) => {
  return (
    <table className={`${className} ${TableWrapper.defaultProps.className}`}>
      {children}
    </table>
  );
};

export const TableHeadWrapper = ({ children, className }) => {
  return <thead className={className}>{children}</thead>;
};

export const TableHead = ({ children, className }) => {
  return <th className={className}>{children}</th>;
};

export const TableBodyWrapper = ({ children, className }) => {
  return <tbody className={className}>{children}</tbody>;
};

export const TableRow = ({ children, className }) => {
  return <tr className={className}>{children}</tr>;
};

export const TableData = ({ children, className }) => {
  return <td className={className}>{children}</td>;
};

//default props

TableWrapper.defaultProps = {
  children: null,
  className: 'table-auto min-w-full mt-2',
};

TableHeadWrapper.defaultProps = {
  children: null,
  className: 'bg-blue-700 text-white',
};

TableHead.defaultProps = {
  children: null,
  className:
    'px-6 py-6 text-center text-sm font-bold text-lg text-white uppercase  tracking-wide',
};

TableBodyWrapper.defaultProps = {
  children: null,
  className:
    'bg-white divide-y divide-gray-200 border-b-2 border-l-2 border-r-2 border-solid border-grey-300',
};

TableRow.defaultProps = {
  children: null,
  className: 'divide-x divide-gray-300',
};

TableData.defaultProps = {
  children: null,
  className: 'px-6 py-2 whitespace-nowrap text-center text-md text-black',
};
