import React from 'react'
import SearchIcon from '../../../assets/icon/search.svg'

import FormInputIcon from '../../molecules/FormInputIcon'


export default function Searchbox() {
  return (
    <div className="p-2 m-2">
        <FormInputIcon
          srcImg={SearchIcon}
          formInputClassName={'w-[300px] rounded-xl pl-10 p-2 text-black border border-solid border-black'}
          classNameImg={'size-8 pl-2'}
          formInputPlaceholder={'Cari...'}
        />
      </div>
  )
}
