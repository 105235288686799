import React from 'react';
import FormInputAuth from '../../../components/molecules/FormInputAuth';

import UserLogo from '../../../assets/auth/login/user_circle.svg';
import Button from '../../../components/atoms/Button';

const ForgotPasswordForms = () => {
  return (
    <div className='grid grid-cols-1 sm:grid-cols-1 h-screen w-full'>
      <div className='flex flex-col justify-center'>
        <form className='max-w-[600px] max-h-[650px] h-[500px] w-7/12 mx-auto py-11 bg-[#1e40af] p-8 rounded-lg'>
          <h2 className='text-white text-start font-bold text-4xl mb-10'>
            Lupa Password
          </h2>
          <p className='text-white text-start'>
            Masukkan Email Anda dan kami akan mengirimkan tautan untuk mereset
            kata sandi Anda.
          </p>
          <FormInputAuth
            label='Email'
            srcImg={UserLogo}
            altImg='User'
            classNameImg='absolute size-8 pl-2'
            // eventSetter={setDataUsername}
            formInputClassName='h-[50px] rounded-lg pl-10 p-2 text-black'
            formInputType='text'
            formInputPlaceholder='Email'
            formInputRequired
          />
          <div className='w-full xl:w-3/4 mx-auto mt-5'>
            <Button
              title='Kirim'
              type='submit'
              // onClick={handleSubmit}
              className='btn-login'
            />
          </div>
          <p className='text-white text-center mt-5'>Kembali ke login.</p>
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordForms;
