import React from 'react';
import Div from '../../atoms/Div';
import Img from '../../atoms/Img';
import Button from '../../atoms/Button';

//props tiap component yang dibutuhkan
//1. Div --> childrenDiv, classNameDiv, styleDiv, onClickDiv;
//2. Img --> srcImg, altImg, classNameImg;
//3. Button --> childrenButton, titleButton, typeButton, classNameButton, onClickButton

export default function ButtonIcon({
  classNameDiv,
  styleDiv,
  onClickDiv,
  srcImg,
  altImg,
  classNameImg,
  childrenButton,
  titleButton,
  typeButton,
  classNameButton,
  onClickButton,
}) {
  switch (classNameDiv) {
    case 'btn-detail-img':
      classNameDiv =
        'flex flex-row rounded-md w-fit p-2 bg-blue-700 hover:bg-blue-500 text-white gap-1 cursor-pointer';
      break;
    case 'btn-edit-img':
      classNameDiv =
        'flex flex-row rounded-md w-fit p-2 bg-yellow-700 hover:bg-yellow-500 text-white gap-1 cursor-pointer';
      break;
    case 'btn-add-img':
      classNameDiv =
        'flex flex-row rounded-md w-fit p-2 bg-green-700 hover:bg-green-500 text-white gap-1 cursor-pointer';
      break;
    case 'btn-delete-img':
      classNameDiv =
        'flex flex-row rounded-md w-fit p-2 bg-red-700 hover:bg-red-500 text-white cursor-pointer';
      break;
    case 'btn-unactive':
      classNameDiv =
        'flex w-fit p-2 cursor-pointer';
      break;
    case 'btn-active':
      classNameDiv =
        'flex w-fit p-2 cursor-pointer';
      break;
    default:
      classNameDiv = 'flex w-fit p-2 cursor-poiner bg-white gap-2';
  }
  return (
    <Div className={classNameDiv} style={styleDiv} onClick={onClickDiv}>
      <Img srcImg={srcImg} altImg={altImg} classNameImg={classNameImg} />
      <Button
        children={childrenButton}
        title={titleButton}
        type={typeButton}
        className={classNameButton}
        onClick={onClickButton}
      />
    </Div>

  );
}

ButtonIcon.defaultProps = {
  classNameDiv: 'flex flex-col ',
  titleButton: '',
  onClickButton: () => { },
};