import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

//import hooks
import { useAuth } from '../../../hooks/useAuth';

//assets
import UserLogo from '../../../assets/auth/login/user_circle.svg';
import LockLogo from '../../../assets/auth/login/lock.svg';

//import components
import Button from '../../atoms/Button';
import FormInputAuth from '../../molecules/FormInputAuth';

export default function LoginForms({ title, dataUser }) {
  const [dataUsername, setDataUsername] = useState('');
  const [dataPassword, setDataPassword] = useState('');

  const navigate = useNavigate();
  const { login } = useAuth();

  const verifyUser = (id, password) => {
    const formDataUser = {
      id,
      password,
    };
    const user = dataUser.find(
      ({ id, password }) =>
        id === formDataUser.id && password === formDataUser.password
    );
    return user;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const id = dataUsername;
    const password = dataPassword;
    const user = verifyUser(id, password);
    if (login && user) {
      login({ name: 'Abdus Salim', role: 'Admin Super' });
      navigate('/');
    } else {
      console.log('Login gagal');
      navigate('/auth/login');
    }
  };

  return (
    <div className='grid grid-cols-1 sm:grid-cols-1 h-screen w-full'>
      <div className='flex flex-col justify-center'>
        <form className='max-w-[600px] max-h-[650px] h-[500px] w-7/12 mx-auto py-11 bg-[#1e40af] p-8 rounded-lg'>
          <h2 className=' text-white text-center font-bold text-4xl mb-10'>
            {title}
          </h2>
          {/* username input */}
          <FormInputAuth
            label='Username'
            srcImg={UserLogo}
            altImg='User'
            classNameImg='absolute size-8 pl-2'
            eventSetter={setDataUsername}
            formInputClassName='h-[50px] rounded-lg pl-10 p-2 text-black'
            formInputType='text'
            formInputPlaceholder='Username'
            formInputRequired
          />
          {/* password input */}
          <FormInputAuth
            label='Password'
            srcImg={LockLogo}
            altImg='Lock'
            classNameImg='absolute size-8 pl-2'
            eventSetter={setDataPassword}
            formInputClassName='h-[50px] rounded-lg pl-10 p-2 text-black'
            formInputType='password'
            formInputPlaceholder='Password'
            formInputRequired
          />
          {/* login button */}
          <div className='w-full xl:w-3/4 mx-auto mt-10'>
            <Button
              title='Login'
              type='submit'
              onClick={handleSubmit}
              className='btn-login'
            />
          </div>
        </form>
      </div>
    </div>
  );
}
