//Import for sidebar menu icons
import DashboardIcon from '../assets/icon/home.svg';
import StudentIcon from '../assets/icon/student.svg';
import TeacherIcon from '../assets/icon/teacher.svg';
import MailerIcon from '../assets/icon/mail.svg';
import InventoryIcon from '../assets/icon/archive.svg';

//User Dummy
export const user = {
  id: 'admin',
  password: 'admin123',
  name: 'Abdus Salim',
  role: 'Super Admin',
};

//SIDEBAR MENU
//Sidebar menu reference
export const sidebarMenuReference = {
  dashboard: ['Dashboard', DashboardIcon],
  siswa: ['Siswa', StudentIcon],
  guru: ['Guru', TeacherIcon],
  jadwal: ['Jadwal', ''],
  persuratan: ['Persuratan', MailerIcon],
  inventaris: ['Inventaris', InventoryIcon],
};

//Sidebar sub menu reference
export const sidebarSubMenuReference = {
  dashboard: ['Dashboard'],
  siswa: ['Data Siswa', 'Absensi Siswa', 'Raport Siswa'],
  guru: ['Data Guru', 'Absensi Guru', 'Wali Kelas'],
  jadwal: ['Jadwal Siswa', 'Jadwal Guru'],
  persuratan: ['Surat Masuk', 'Surat Keluar'],
  inventaris: ['Barang Masuk', 'Barang Keluar'],
};
