import React, { Suspense } from 'react';
import { useOutlet, Await } from 'react-router-dom';

//import component
import LinearProgress from '@material-ui/core/LinearProgress';
import BarangMasuk from '../../../pages/inventory/BarangMasuk';

export default function BarangMasukOutlet() {
  const outlet = useOutlet();
  return (
    <Suspense fallback={<LinearProgress />}>
      <Await children={() => <BarangMasuk>{outlet}</BarangMasuk>} />
    </Suspense>
  );
}
