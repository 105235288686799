import React, { useEffect, useState } from 'react';
import Button from '../../atoms/Button';

const Dropdown = ({ title, dataDummy }) => { // Mengubah prop 'item' menjadi 'items' untuk merepresentasikan array
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('-');
    useEffect(() => {
        if (title === 'Kelas :') {
            setSelectedOption('X');
        }
    }, [title]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option.name); // Mengambil nama opsi yang dipilih
        setIsOpen(false); // Tutup dropdown setelah opsi dipilih
    };

    return (
        <div className="flex gap-4 items-center">
            {title}
            <div className="relative inline-block text-left items-center justify-center w-56">
                <div className="w-28">
                    <Button onClick={toggleDropdown} className={'btn-default'}>
                        {selectedOption}
                    </Button>
                </div>
                {isOpen && (
                    <div className="absolute w-full">
                        <div className="grid grid-rows-3 justify-center w-28 py-1 px-2 border border-black rounded-lg bg-white">
                            {dataDummy.map((option) => (
                                <Button key={option.name} title={option.name} onClick={() => handleOptionClick(option)} className={'btn-dropdown'}/> // Menggunakan data dummy untuk membuat tombol dropdown
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Dropdown;
