import React,  { Suspense }  from 'react'
import { useOutlet, Await } from 'react-router-dom';

import LinearProgress from '@material-ui/core/LinearProgress';
import DetailWaliKelas from '../../../../pages/teacher/WaliKelas/DetailWaliKelas';


export default function DetailWaliKelasOutlet() {
    const outlet = useOutlet();
    return (
        <Suspense fallback={<LinearProgress />}>
        <Await children={() => <DetailWaliKelas>{outlet}</DetailWaliKelas>} />
      </Suspense>
    );
    }
