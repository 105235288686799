import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

export default function PublicLayout() {
  const { user } = useAuth();

  if (user) {
    return <Navigate to='/dashboard' />;
  }
  return <Outlet />;
}
