import React, { Suspense } from 'react'
import { useOutlet, Await } from 'react-router-dom';

//import component
import LinearProgress from '@material-ui/core/LinearProgress';
import DetailGuru from '../../../../pages/teacher/DataGuru/DetailGuru';


export default function DetailGuruOutlet() {
    const outlet = useOutlet();
  return (
    <Suspense fallback={<LinearProgress />}>
    <Await children={() => <DetailGuru>{outlet}</DetailGuru>} />
  </Suspense>
);
}
