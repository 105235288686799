import React from 'react'
import ButtonIcon from '../../../molecules/ButtonIcon'
import AddIcon from '../../../../assets/icon/add_ring.svg'

export default function EmptyState() {
    return (
        <div>
            <div className='flex flex-col items-center justify-center gap-6 p-2 w-auto h-auto'>
                <div className="w-fit h-fit border-[2px] border-gray-700 border-dashed p-2">
                    <div className='text-center'>
                        <h1 className='text-lg font-semibold text-black'>No data found!</h1>
                        <p className='text-sm font-medium text-gray-600'>click "add data" button for fill the data in table!</p>
                    </div>
                    <div className='flex flex-row gap-6 items-center justify-center'>
                        <ButtonIcon srcImg={AddIcon} classNameDiv={'btn-add-img'} titleButton={'Add Data'} />
                    </div>
                </div>
            </div>
        </div>
    )
}
