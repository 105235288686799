import React, { useEffect, useState } from 'react';
import './style.css';
import ContentLayout from '../../../components/organisms/Shared/ContentLayout';
import Table from '../../../components/organisms/Shared/Table';

const WaliKelas = ({ teacherData }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(teacherData);
  }, [teacherData]);


  const tableDetails = {
    isNo: true,
    fields: [
      {
        title: 'NAMA',
        fieldName: 'username',
      },
      {
        title: 'WALI KELAS',
        fieldName: 'postId',
      },
      {
        title: 'JURUSAN',
        fieldName: 'id',
      },
    ],
    data: data,

    actions: {
      isAction: true,
      isDetail: true,
      isEdit: false,
      isDelete: true,
      isPagination: false,
    },

    attributes: {
      isAdd: true,
      isSearch: true,
      isPagination: true,
    },
    pathname: '/guru/wali-kelas/detail-wali-kelas'
  };

  return (
    <ContentLayout>
      <Table tableDetails={tableDetails} itemsPerPage={7} title={'Action'} />
    </ContentLayout>
  );
};

export default WaliKelas;
