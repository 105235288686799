import React from 'react';
import Img from '../../atoms/Img';

export default function Card({ title, 
    subtitle, 
    altImg, 
    logo, 
    classNameCard, 
    classNameLine }) {
    switch (classNameLine) {
        case 'red-line':
            classNameLine =
                'w-1/2 border-t-4 border-red-900 rounded-full my-2'
            break;
        case 'blue-line':
            classNameLine =
                'w-1/2 border-t-4 border-blue-900 rounded-full my-2'
            break;
        case 'yellow-line':
            classNameLine =
                'w-1/2 border-t-4 border-yellow-900 rounded-full my-2'
            break;
        case 'green-line':
            classNameLine =
                'w-1/2 border-t-4 border-green-900 rounded-full my-2'
            break;
        case 'neutral-line':
            classNameLine =
                'w-1/2 border-t-4 border-black rounded-full my-2'
            break;
        default:
            classNameLine = '';
    }

    switch (classNameCard) {
        case 'red-card':
            classNameCard =
                'w-40 h-28 gap-2 grid bg-red-500 p-2 rounded-lg'
            break;
        case 'blue-card':
            classNameCard =
                'w-40 h-28 gap-2 grid bg-blue-500 p-2 rounded-lg'
            break;
        case 'green-card':
            classNameCard =
                'w-40 h-28 gap-2 grid bg-green-500 p-2 rounded-lg'
            break;
        case 'yellow-card':
            classNameCard =
                'w-40 h-28 gap-2 grid bg-yellow-500 p-2 rounded-lg'
            break;
        case 'neutral-card':
            classNameCard =
                'w-40 h-28 gap-2 grid bg-slate-500 p-2 rounded-lg'
            break;
        default:
            classNameCard = '';

        // switch (classNameTitle) {

        // }
    }
    return (
        <div className={classNameCard}>
            <div className="flex items-center text-start text-xs gap-2">
                <Img srcImg={logo} altImg={altImg} classNameImg='size-[24px]' />
                <p className="text-[12px] font-bold text-slate-950">{title}</p>
            </div>
            <div className="flex flex-col items-center rounded">
                <div className="text-2xl text-center font-semibold">{subtitle}</div>
                <hr className={classNameLine} />
            </div>
        </div>
    );
}