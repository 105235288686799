import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { sidebarSubMenuReference } from '../../../references';
import Button from '../../atoms/Button';
import Svg from '../../atoms/Svg';
import Img from '../../atoms/Img';

function Accordion({ title, srcImg, altImg, isOpen, onClick }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const classNameImg = 'ps-2 py-2';

  const handleAccordionClick = () => {
    onClick(); // Panggil fungsi onClick dari prop
    if (title === 'Dashboard') {
      navigate('/dashboard');
    }
  };

  const handleSubMenuClick = (menu) => {
    if (pathname.split('/')[1] !== title.toLowerCase()) {
      onClick(); // Panggil fungsi onClick dari prop
    }
    console.log("MENU:", title);
    console.log("SUB MENU:", menu);

    //buat Siswa menjadi /siswa 
    console.log("menjadi /siswa:", `/${title.toLowerCase()}`);

    //buat Data Siswa menjadi /data-siswa, string -> Data Siswa = menu -> toLowerCase -> data siswa -> split(" ") -> data,siswa -> join('-')
    console.log("menjadi /data-siswa:", `/${menu.toLowerCase().split(" ").join("-")}`);

   //PATHNAME
   console.log("gabungan join:", `/${title.toLowerCase()}/${menu.toLowerCase().split(" ").join("-")}`);

   //PATHNAME
   console.log("gabungan replace", `/${title.toLowerCase()}/${menu.toLowerCase().replace(/\s+/g, '-')}`);

   //NAVIGATE
  navigate(`/${title.toLowerCase()}/${menu.toLowerCase().replace(/\s+/g, '-')}`)
  };

  return (
    <div className='max-w-md mx-auto my-1 '>
      <div className='border border-gray-200 rounded-lg bg-[#000080] '>
        <div
          className={`grid grid-cols-3 hover:bg-blue-800 rounded-lg ${
            isOpen ? 'border-b' : ''
          } `}
          onClick={handleAccordionClick}
        >
          <div className='col'>
            <Img srcImg={srcImg} altImg={altImg} classNameImg={classNameImg} />
          </div>
          <div className='col'>
            <h3 className='text-md font-semibold mt-2 text-lg'>{title}</h3>
          </div>
          <div className='col'>
            {title !== 'Dashboard' && (
              <Svg
                classNameSvg={`h-6 w-6 mt-3 ms-8 ${
                  isOpen ? 'duration-300 -rotate-180' : 'duration-300 rotate-0'
                }`}
                fillSvg='none'
                strokeSvg='currentColor'
                viewBoxSvg='0 0 24 24'
                xmlnsSvg='http://www.w3.org/2000/svg'
                strokeLinecapPath='round'
                strokeLinejoinPath='round'
                strokeWidthPath={3}
                dPath='M19 9l-7 7-7-7'
              />
            )}
          </div>
        </div>
        <div
          className={`${title === 'Dashboard' ? 'hidden' : 'grid'}
          transition-all duration-500 ease-in-out 
          ${
            isOpen && (title === 'Siswa') | (title === 'Guru') | (title === 'Jadwal') 
              ? 'opacity-100 py-2 h-24'
              : 'opacity-0 h-0'
          }
          ${
            isOpen && (title === 'Persuratan') | (title === 'Inventaris')
              ? 'opacity-100 py-2 min-h-16'
              : 'opacity-0 min-h-0'
          }
          `}
        >
          {isOpen &&
            sidebarSubMenuReference[title.toLowerCase()]?.map((menu, index) => (
              <Button
                key={index}
                title={menu}
                onClick={() => handleSubMenuClick(menu)}
                className={'hover-btn-default'}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default Accordion;
