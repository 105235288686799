import React, { useState } from 'react';

//import icons
import AccountIcon from '../../../../assets/icon/student.svg';

//import references
import { user } from '../../../../references';

//import components
import Img from '../../../atoms/Img';

//import hooks
import { useAuth } from '../../../../hooks/useAuth';

export default function Header({ pathname, pageTitle }) {
  const { name } = user;
  const [isOpen, setIsOpen] = useState(false);

  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <nav className='bg-white shadow-lg'>
        <div className='container mx-auto px-4'>
          <div className='flex justify-between items-center h-16'>
            {/* Brand */}
            <div className='flex-shrink-0'>
              <a href={pathname} className='font-bold text-xl'>
                {pageTitle}
              </a>
            </div>

            {/* User Dropdown */}
            <div className='relative' onClick={() => setIsOpen(!isOpen)}>
              <button className='flex items-center focus:outline-none'>
                <span className='text-gray-600 text-md'>Hi, {name}</span>
                <Img
                  srcImg={AccountIcon}
                  classNameImg={'mx-5 border-2 border-black rounded-full'}
                />
              </button>

              {/* Dropdown Menu */}
              {isOpen && (
                <div className='absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg overflow-hidden z-10'>
                  <a
                    href='/'
                    className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                  >
                    Profile
                  </a>
                  <a
                    href='/'
                    className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                  >
                    Settings
                  </a>
                  <a
                    href='/'
                    className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                    onClick={handleLogout}
                  >
                    Logout
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
