import React, { Suspense } from 'react';
import { useLoaderData, useOutlet, Await } from 'react-router-dom';

//import component
import LinearProgress from '@material-ui/core/LinearProgress';
import AbsensiSiswa from '../../../pages/student/AbsensiSiswa';

export default function AbsensiSiswaOutlet() {
  const outlet = useOutlet();
  const { studentData } = useLoaderData();
  return (
    <Suspense fallback={<LinearProgress />}>
      <Await
        resolve={studentData}
        children={(studentData) => (
          <AbsensiSiswa studentData={studentData}>{outlet}</AbsensiSiswa>
        )}
      />
    </Suspense>
  );
}
