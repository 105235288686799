import React from 'react';

const ContentLayout = ({ children, height, style }) => {
  return (
    <div
      className={`w-full xl:h-[${height}] bg-white shadow-lg md:w-3/4 lg:w-9/12 xl:w-11/12 mt-6 mx-auto mt-a border border-solid border-black rounded-3xl`}
      style={style}
    >
      {children}
    </div>
  );
};

ContentLayout.defaultProps = {
  height: 'auto',
  style: {},
};

export default ContentLayout;
