import React, { useEffect, useState } from 'react';
import './style.css';
import ContentLayout from '../../../components/organisms/Shared/ContentLayout';
import Table from '../../../components/organisms/Shared/Table';

const kelas = [
  { name: 'X' },
  { name: 'XI' },
  { name: 'XII' },
];
const jurusan = [
  { name: '-' },
  { name: 'IPA' },
  { name: 'IPS' },
];

const RaportSiswa = ({ studentData }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(studentData);
  }, [studentData]);

  const tableDetails = {
    isNo: true,
    fields: [
      {
        title: 'NAMA',
        fieldName: 'fullname',
      },
    ],
    data: data,

    actions: {
      isAction: true,
      isDetail: true,
      isEdit: false,
      isDelete: false,
      isPagination: false,
    },

    attributes: {
      isAdd: true,
      isSearch: true,
      isPagination: true,
    },
  };
  return (
    <ContentLayout>
      <Table tableDetails={tableDetails} itemsPerPage={7} title={'Action'} itemsData={kelas} itemsData2={jurusan}/>
    </ContentLayout>
  );
};

export default RaportSiswa;
