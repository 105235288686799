import React from 'react';

export default function Div({ children, className, style, onClick }) {
  return (
    <div className={className} style={style} onClick={onClick}>
      {children}
    </div>
  );
}

Div.defaultProps = {
  children: null,
  className: '',
  style: {},
  onClick: () => {},
};
