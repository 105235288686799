import React from 'react';

export default function Button({ children, title, type, className, onClick }) {

  const classMapping ={
    'btn-login': 'h-[50px] font-bold bg-[#fad133] text-lg w-full rounded-lg p-2 mt-4',
    'btn-add': 'bg-green-600 hover:bg-green-500 text-sm text-white font-bold py-2 px-4 rounded',
    'btn-edit': 'bg-yellow-600 hover:bg-yellow-500 text-sm text-white font-bold py-2 px-4 rounded mr-2',
    'btn-delete': 'bg-red-600 hover:bg-red-500 text-sm text-white font-bold py-2 px-4 rounded',
    'btn-detail': 'bg-blue-500 hover:bg-blue-600 text-sm text-white font-bold py-2 px-4 rounded mr-2',
    'btn-prev': 'relative inline-flex items-center px-2 py-1 text-sm font-medium text-white bg-blue-700 border border-gray-300 rounded-l-md hover:bg-blue-500',
    'btn-next': 'relative inline-flex items-center px-2 py-1 text-sm font-medium text-white bg-blue-700 border border-gray-300 rounded-l-md hover:bg-blue-500',
    'btn-dropdown': 'py-1 px-10 w=[100%] font-medium text-black hover:bg-gray-500 text-sm rounded-lg',
    'btn-default': 'py-2 px-4 w-[100%] font-medium border border-gray-700 text-black text-sm rounded-lg',
    'hover-btn-default': 'hover:bg-blue-800 text-md text-white font-normal py-0 px-4 rounded',
  }

  let classNames = ''
  className.split(' ').map((classes)=>{ 
    console.log("classes", classes); 
    return classes in classMapping ? 
    classNames = String.prototype.concat(classNames, ' ', classMapping[classes]):
    classNames = String.prototype.concat(classNames, ' ', classes)
  })

  console.log("classNames", classNames)

  className = classNames

  return (
    <button type={type} onClick={onClick} className={className}>
      {children || title}
    </button>
  );
}

Button.defaultProps = {
  title: 'cek',
  type: 'submit',
  className: '',
  onClick: () => { },
};
