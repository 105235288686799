import React, { useState } from 'react';

//import assets
import SearchIcon from '../../../../assets/icon/search.svg';
import DetailIcon from '../../../../assets/icon/info_alt.svg'
import DeleteIcon from '../../../../assets/icon/trash.svg'
import AddIcon from '../../../../assets/icon/add_ring.svg'
import VerifyIcon from '../../../../assets/icon/verify_unactive.svg'
import InfoIcon from '../../../../assets/icon/warn_unactive.svg'
import DelIcon from '../../../../assets/icon/close_unactive.png'
import EditIcon from '../../../../assets/icon/Edit_fill.svg'

//import components
import {
  TableWrapper,
  TableHeadWrapper,
  TableBodyWrapper,
  TableHead,
  TableRow,
  TableData,
} from '../../../atoms/TableComponents';
import FormInputIcon from '../../../molecules/FormInputIcon';
import Pagination from '../../../molecules/Pagination';
// import Button from '../../../atoms/Button';
import ButtonIcon from '../../../molecules/ButtonIcon';
import Dropdown from '../../../molecules/Dropdown';
import { useNavigate } from 'react-router';

const Table = ({ tableDetails, itemsPerPage, title, itemsData, itemsData2 }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentItems = tableDetails?.data?.slice(firstIndex, lastIndex);
  const totalPages = Math.ceil(tableDetails?.data?.length / itemsPerPage);

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(tableDetails?.pathname  || '/dashboard');
  }

  // const onDetail = (id) => {
  //   console.log('Detail', id);
  // };
  const onEdit = (id) => {
    console.log('Edit', id);
  };
  const onDelete = (id) => {
    console.log('Delete', id);
  };

  return (
    <>
      <div className='flex flex-col my-4 mx-4 justify-items-end z-0'>
        <div className='flex flex-row items-center justify-between w-full'>
          {tableDetails?.attributes?.isSearch && (
            <FormInputIcon
              srcImg={SearchIcon}
              classNameImg='size-8 pl-2'
              formInputClassName={
                'w-auto rounded-xl pl-10 p-2 text-black border border-solid border-black'
              }
              formInputPlaceholder={'Cari Data ...'}
            />
          )}
          <div className="flex flex-row items-start justify-center">
          {tableDetails?.attributes?.isDropdown && (
            <Dropdown 
            title={'Kelas :'}  
            dataDummy={itemsData}
            />
          )}
          {tableDetails?.attributes?.isDropdown && (
            <Dropdown 
            title={'Jurusan :'}  
            dataDummy={itemsData2}
            />
          )}
          </div>
          <div className="flex flex-col items-start justify-center">
            {tableDetails?.attributes?.isAdd && (
              // <Button
              //   title={'Tambahkan'}
              //   className={'btn-add'}
              //   onClick={() => {}}
              // />

              <ButtonIcon
                srcImg={AddIcon}
                titleButton={'Tambahkan'}
                classNameDiv={'btn-add-img'}
                onClickButton={() => { }}
              />
            )}
            {tableDetails?.attributes?.isEdits && (
              <ButtonIcon
                srcImg={EditIcon}
                titleButton={'Edit'}
                classNameDiv={'btn-edit-img'}
                onClickButton={handleClick}
              />
            )}
          </div>
        </div>
        <TableWrapper>
          <TableHeadWrapper>
            <TableRow>
              {tableDetails?.isNo && <TableHead className={'w-[10px]'}>No</TableHead>}
              {tableDetails?.fields?.map((item, index) => (
                <TableHead key={index}>{item.title}</TableHead>
              ))}
              {tableDetails?.actions?.isAction && <TableHead className={'w-[300px] grid-cols-2'}>
                {title}
                {tableDetails?.actions?.isSubAction &&
                  <div className="flex gap-8 items-center justify-center border-t">
                    <p>Hadir</p>
                    <p>Absen</p>
                    <p>Izin</p>
                  </div>
                }
              </TableHead>}
            </TableRow>
          </TableHeadWrapper>
          <TableBodyWrapper>
            {currentItems?.map((item, index) => (
              <TableRow key={index}>
                {tableDetails?.isNo && <TableData>{item.id}</TableData>}
                {tableDetails?.fields?.map((field, index) => (
                  <TableData key={index}>{item[field.fieldName]}</TableData>
                ))}
                {tableDetails?.actions?.isAction && (
                  <TableData>
                    <div className="flex gap-5 justify-center">
                      {tableDetails?.actions?.isDetail && (
                        // <Button
                        //   title={'Detail'}
                        //   className={'btn-detail'}
                        //   onClick={() => {
                        //     onDetail(item.id);
                        //   }}
                        // />
                        <ButtonIcon
                          srcImg={DetailIcon}
                          titleButton={'Detail'}
                          classNameDiv={'btn-detail-img'}
                          onClickDiv={handleClick}
                        />
                      )}
                      {tableDetails?.actions?.isEdit && (
                        // <Button
                        //   title={'Edit'}
                        //   className={'btn-edit'}
                        //   onClick={() => {
                        //     onEdit(item.id);
                        //   }}
                        // />
                        <ButtonIcon
                          srcImg={EditIcon}
                          titleButton={'Edit'}
                          classNameDiv={'btn-edit-img'}
                          onClickButton={() => [
                            onEdit(item.id)
                          ]}
                        />
                      )}
                      {tableDetails?.actions?.isDelete && (
                        // <Button
                        //   title={'Hapus'}
                        //   className={'btn-delete'}
                        //   onClick={() => {
                        //     onDelete(item.id);
                        //   }}
                        // />
                        <ButtonIcon
                          srcImg={DeleteIcon}
                          classNameDiv={'btn-delete-img'}
                          onClickButton={() => [
                            onDelete(item.id)
                          ]}
                        />
                      )}
                      {tableDetails?.actions?.isVerify && (
                        <ButtonIcon
                          srcImg={VerifyIcon}
                          classNameDiv={'btn-unactive'}
                          onClickButton={() => [
                            onDelete(item.id)
                          ]}
                        />
                      )}
                      {tableDetails?.actions?.isInfo && (
                        <ButtonIcon
                          srcImg={InfoIcon}
                          classNameDiv={'btn-unactive'}
                          onClickButton={() => [
                            onDelete(item.id)
                          ]}
                        />
                      )}
                      {tableDetails?.actions?.isDel && (
                        <ButtonIcon
                          srcImg={DelIcon}
                          classNameDiv={'btn-unactive'}
                          onClickButton={() => [
                            onDelete(item.id)
                          ]}
                        />
                      )}
                    </div>
                  </TableData>
                )}
              </TableRow>
            ))}
          </TableBodyWrapper>
        </TableWrapper>
        {tableDetails?.attributes?.isPagination && (
          <div className='flex justify-center mt-4'>
            <Pagination
              firstIndex={firstIndex}
              lastIndex={lastIndex}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
              data={tableDetails?.data}
            />
          </div>
        )}
      </div>
    </>
  );
};

Table.defaultProps = {
  tableDetails: {},
  onEdit: () => { },
  onDetail: () => { },
  onDelete: () => { },
  itemsPerPage: 6,
};

export default Table;
