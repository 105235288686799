import React from 'react';
import { useAuth } from '../../../hooks/useAuth';

const NotFound = () => {
  const { user } = useAuth();
  return (
    <div className='min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8'>
      <div className='max-w-md w-full space-y-8'>
        <div>
          <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
            404 - Halaman Tidak Ditemukan
          </h2>
        </div>
        <div className='text-center'>
          <p className='text-sm text-gray-500'>
            Maaf, halaman yang Anda cari tidak ditemukan.
            <span className='block'>
              <a
                href='/'
                className='font-medium text-indigo-600 hover:text-indigo-500'
              >
                {user !== null ? 'Kembali ke Dashboard' : 'Kembali ke Login'}
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
