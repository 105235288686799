import React from 'react';
import './style.css';

import ForgotPasswordForms from '../../../components/organisms/ForgotPasswordForms';

import SmasaLogo from '../../../assets/auth/login/logo-smasa-kapuas.png';

import Img from '../../../components/atoms/Img';

const ForgotPassword = () => {
  return (
    <div>
    <div className='flex h-screen w-full'>
        <div className='hidden lg:flex flex-col text-center h-dvh w-2/3 mr-6 bg-blue-800'>
          <h1 className='text-white text-2xl xl:text-5xl font-bold mt-20 mx-auto'>
            {' '}
            Website Data Didik SMA Negeri 1 Kapuas
          </h1>
          <Img
            classNameImg={'flex mx-auto mt-20 w-50 h-50'}
            srcImg={SmasaLogo}
          />
        </div>
        <ForgotPasswordForms />
      </div>
      
    </div>
  );
};



export default ForgotPassword;
