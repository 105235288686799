import React, { useEffect, useState } from 'react';
import './style.css';

//import components
import ContentLayout from '../../../components/organisms/Shared/ContentLayout';
import Table from '../../../components/organisms/Shared/Table';

const AbsensiSiswa = ({ studentData }) => {
  const [data,setData] = useState([]);

  useEffect(() => {
    setData(studentData);
  }, [studentData]);

  const tableDetails = {
    isNo: true,

    fields: [
      {
        title: 'NAMA',
        fieldName: 'fullname',
      },
    ],
    data: data,
    actions: {
      isAction: true,
      isDetail: false,
      isEdit: false,
      isDelete: false,
      isPagination:false,
      isVerify: true,
      isInfo: true,
      isDel: true,
      isSubAction: true,
    },

    attributes: {
      isAdd: false,
      isSearch: true,
      isPagination: true,
      isEdits: false,
    },
  };
  return (
    <ContentLayout>
      <Table tableDetails={tableDetails} itemsPerPage={5} title={'Absensi'}/>
    </ContentLayout>
  );
};

export default AbsensiSiswa;
