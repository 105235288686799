export const auth = {
  services: {
    get: {
      getUserData: () =>
        new Promise((resolve) =>
          setTimeout(() => {
            const user = window.localStorage.getItem('user');
            resolve(user);
          }, 1000)
        ),
    },
  },
};
