import React from 'react';
import './style.css';
import ContentLayout from '../../components/organisms/Shared/ContentLayout';
import Overview from '../../components/organisms/Overview';
import Modal from '../../components/organisms/Shared/Modal';

const Dashboard = () => {
  return (
    <ContentLayout height={'4/5'}>
      <div className='flex flex-col justify-center items-left h-full ml-5'>
        <h1 className='text-4xl font-bold px-2 py-2'>Dashboard</h1>
        {/* <div className="w-1/3 h-24 bg-blue-400 rounded-lg p-2 text-white">
          <p>Welecome Back Sir</p>
        </div> */}
      </div>
      <div className="grid grid-cols-2 gap-4 p-2">
        <div>
          <h2 className='text-3x1 font-bold ml-5 px-2 py-2'>Overview</h2>
          <Overview
            studentSum={20}
            teacherSum={10}
            schoolDevices={50}
            inventoryIn={40}
            inventoryOut={30}
            lettersIn={50}
            lettersOut={50} />
        </div>
        <div className='w-96 h-96 border border-black m-2'>
          <h2 className='text-3x1 font-bold ml-5 px-2 py-2'>Log Activity</h2>
        </div>
      </div>
      <Modal title={'Open Modal'} classNameModal={'btn-detail'}/>
    </ContentLayout>
  );  //dummy
};

export default Dashboard;
