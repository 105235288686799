import React from 'react';
import Card from '../../molecules/Card';
import StudentIcon from '../../../assets/icon/student.svg'
import TeacherIcon from '../../../assets/icon/teacher.svg'
import InventoryIcon from '../../../assets/icon/archive.svg'

export default function Overview({ 
  studentSum, 
  teacherSum,
  schoolDevices, 
  inventoryIn, 
  inventoryOut, 
  lettersIn, 
  lettersOut }) {
  return (
      <div className="grid grid-cols-3 gap-6 m-2">
         <Card
          classNameCard={'blue-card'}
          title={'Jumlah Siswa'}
          subtitle={studentSum}
          logo={StudentIcon}
          classNameLine={'blue-line'}
        />
         <Card
          classNameCard={'blue-card'}
          title={'Jumlah Guru'}
          subtitle={teacherSum}
          logo={TeacherIcon}
          classNameLine={'blue-line'}
        />
        <Card
          classNameCard={'blue-card'}
          title={'Jumlah Perangkat Sekolah'}
          subtitle={schoolDevices}
          logo={InventoryIcon}
          classNameLine={'blue-line'}
        />
        <Card
          classNameCard={'green-card'}
          title={'Jumlah Inventaris Masuk'}
          subtitle={inventoryIn}
          logo={InventoryIcon}
          classNameLine={'green-line'}
        />
        <Card
          classNameCard={'yellow-card'}
          title={'Jumlah Inventaris Keluar'}
          subtitle={inventoryOut}
          logo={InventoryIcon}
          classNameLine={'yellow-line'}
        />
        <Card
          classNameCard={'neutral-card'}
          title={'Jumlah Surat Masuk'}
          subtitle={lettersIn}
          logo={InventoryIcon}
          classNameLine={'neutral-line'}
        />
        <Card
          classNameCard={'neutral-card'}
          title={'Jumlah Surat Keluar'}
          subtitle={lettersOut}
          logo={InventoryIcon}
          classNameLine={'neutral-line'}
        />
      </div>
  );
}


