import React, { useState } from 'react';
import { user, sidebarMenuReference } from '../../../../references';
import Img from '../../../atoms/Img';
import Accordion from '../../../molecules/Accordion';

const Sidebar = (setPageTitle) => {
  const { name, role } = user;
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setOpenAccordionIndex(index === openAccordionIndex ? null : index);
  };

  return (
    <div className='bg-blue-700 w-60 h-screen p-4 text-white sticky z-20'>
      <div className='flex flex-col items-center'>
        <div className='bg-white rounded-full w-32 h-32 mb-4 overflow-hidden'>
          {/* Profile Photo*/}
          <Img
            src='https://placekitten.com/200/200'
            alt='Profile'
            className='w-full h-full object-cover'
          />
        </div>
        <div className='text-center'>
          <p className='text-lg font-semibold'>{name}</p>
          <p className='text-sm'>{role}</p>
        </div>
      </div>

      <div className='mt-5 cursor-pointer'>
        {Object.keys(sidebarMenuReference).map((menu, index) => (
          <Accordion
            key={index}                                 
            title={sidebarMenuReference[menu][0]}
            srcImg={sidebarMenuReference[menu][1]}
            altImg={sidebarMenuReference[menu][0]}
            setPageTitle={setPageTitle}
            isOpen={index === openAccordionIndex}
            onClick={() => handleAccordionClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
