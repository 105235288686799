import React from 'react'
import ContentLayout from '../../../../components/organisms/Shared/ContentLayout'
import Modal from '../../../../components/organisms/Shared/Modal';

const DetailSiswa = () => {
  return (
    <ContentLayout >
   <div className="flex justify-end items-end h-full m-5 mb-5">
    <Modal title={'Edit Data'} classNameModal={'btn-add'} />
    </div>
    
    <div className="flex items-center justify-center mb-10 -mt-10">
    <div className="w-10 h-10 p-10 rounded-full bg-gray-300 flex items-center justify-center">
    <span className=" text-black text-4xl font-bold">A</span>
    </div>

    </div>
    <div className="grid grid-cols-2 gap-2 p-2 ">
      <p><strong>Nama</strong> : Data1</p>
      <p><strong>NIS</strong> : Data2</p>
      <p><strong>Tempat/Tanggal Lahir</strong> : Data3</p>
      <p><strong>Alamat</strong> : Data4 </p>
      <p><strong>Kelas</strong> : Data5</p>
      <p><strong>Jurusan</strong> : Data6</p>
      <p><strong>Semester</strong> : Data7</p>
      <p><strong>Tahun Ajaran</strong> : Data8</p>
      <p><strong>Nilai Rata-rata Raport Keseluruhan</strong> : Data9</p>    
   </div>
    </ContentLayout>
  );
};

export default DetailSiswa;
