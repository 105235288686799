export const teacher = {
  services: {
    get: {
      getTeacherData: () =>
        new Promise((resolve) =>
          setTimeout(async () => {
            await fetch('https://dummyjson.com/comments')
              .then((response) => response.json())
              .then((data) => {
                const newData = data.comments.map((item, index) => {
                  return {
                    ...item,
                    no: index + 1,
                    username: item.user.username,
                    body: item.body,
                  };
                });
                resolve(newData);
              });
          }, 1000)
        ),
    },
  },
};
