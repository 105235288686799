export const student = {
  services: {
    get: {
      getStudentData: () =>
        new Promise((resolve) =>
          setTimeout(async () => {
            await fetch('https://dummyjson.com/users')
              .then((response) => response.json())
              .then((data) => {
                const newData = data.users.map((item, index) => {
                  return {
                    ...item,
                    no: index + 1,
                    fullname: `${item.firstName} ${item.lastName}`,
                    streetAddress: item.address.address,
                  };
                });
                resolve(newData);
              });
          }, 1000)
        ),
    },
  },
};
