import React from 'react';
import './style.css';
import ContentLayout from '../../../components/organisms/Shared/ContentLayout';
import List from '../../../components/organisms/List';
import Searchbox from '../../../components/organisms/Searchbox';
import InventoryIcon from '../../../assets/icon/archive.svg'
import ButtonIcon from '../../../components/molecules/ButtonIcon';

const SuratMasuk = () => {

  return (
    <ContentLayout>
      <div className="Head">
        <Searchbox />
        <div className="max-w-fit ml-5 px-2 flex gap-3 items-center justify-center focus-within:border-b-[3px] border-gray-600">
          <ButtonIcon
            srcImg={InventoryIcon}
            titleButton={'Primary'}
          />
        </div>
      </div>
      <div className="Body">
        <div className="px-2 pb-2">
          <List />
        </div>
      </div>
      <div className="Foot w-full flex gap-10 justify-end p-2">
        
      </div>
    </ContentLayout>
  );
};

export default SuratMasuk;
