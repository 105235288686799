import React from 'react';
import { Navigate, useOutlet, useLocation } from 'react-router-dom';

//import hooks
import { useAuth } from '../hooks/useAuth';

//import components
import Header from '../components/organisms/Shared/Header';
import Sidebar from '../components/organisms/Shared/Sidebar';

//import utils
import sanitizers from '../utils/sanitizers';

export default function ProtectedLayout() {
  const { user } = useAuth();
  const { pathname } = useLocation();
  const outlet = useOutlet();

  if (!user) {
    return <Navigate to='/auth/login' />;
  }

  const pageTitle = sanitizers.pageTitleSanitizedFromPathname(pathname);

  return (
    <div className='flex bg-neutral-300 h-screen w-screen fixed'>
      <aside className='col w-fit'>
        <Sidebar />
      </aside>
      <section className='col w-screen'>
        <header className='sticky top-0 z-10'>
          <Header pathname={pathname} pageTitle={pageTitle} />
        </header>
        <main className='overflow-y-auto h-[90%]'>{outlet}</main>
      </section>
    </div>
  );
}
