import React, { useEffect, useState } from 'react';
import './style.css';

//import components
import Table from '../../../components/organisms/Shared/Table';
import ContentLayout from '../../../components/organisms/Shared/ContentLayout';

const DataSiswa = ({ studentData }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(studentData);
  }, [studentData]);

  const tableDetails = {
    //nomor urut
    isNo: true,
    //field utama yang akan ditampilkan
    fields: [
      {
        title: 'NAMA',
        fieldName: 'fullname',
      },
      {
        title: 'EMAIL',
        fieldName: 'email',
      },
    ],
    //data yang didapakan dari API
    data: data,
    //apakah akan menampilkan action
    actions: {
      isAction: true,
      isDetail: true,
      isEdit: false,
      isDelete: true,
      isPagination: false,
    },
    //apakah akan menampilkan attribute
    attributes: {
      isAdd: true,
      isSearch: true,
      isDropdown: true,
      isPagination: true,
    },
    pathname: '/siswa/data-siswa/detail-siswa'
  };

  const dataDummy = [
    { name: 'X' },
    { name: 'XI' },
    { name: 'XII' },
  ];
  const dataDummy2 = [
    { name: '-' },
    { name: 'IPA' },
    { name: 'IPS' },
  ];
  
  return (
    <ContentLayout>
      <Table tableDetails={tableDetails} itemsPerPage={7} title={'Action'} itemsData={dataDummy} itemsData2={dataDummy2} />
    </ContentLayout>
  );
};

export default DataSiswa;
