import React, { Suspense } from 'react';
import { useOutlet, Await } from 'react-router-dom';

//import component
import LinearProgress from '@material-ui/core/LinearProgress';
import DetailSiswa from '../../../../pages/student/DataSiswa/DetailSiswa';

export default function DetailSiswaOutlet() {
  const outlet = useOutlet();
  return (
    <Suspense fallback={<LinearProgress />}>
      <Await children={() => <DetailSiswa>{outlet}</DetailSiswa>} />
    </Suspense>
  );
}
