import React, { useState } from 'react';

export default function FormInput({
  eventSetter,
  formInputClassName,
  formInputType,
  formInputPlaceholder,
  formInputRequired,
}) {
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <input
      className={formInputClassName}
      type={formInputType}
      placeholder={formInputPlaceholder}
      required={formInputRequired}
      value={inputValue}
      onChange={(event) => {
        handleChange(event);
      }}
      onKeyUp={(event) => {
        eventSetter(event.target.value);
      }}
    />
  );
}

FormInput.defaultProps = {
  eventSetter: () => {},
  formInputClassName: '',
  formInputType: 'text',
  formInputPlaceholder: 'Placeholder Input ...',
  formInputRequired: false,
};
