import React from 'react';
import './style.css';
import ContentLayout from '../../../components/organisms/Shared/ContentLayout';
import Table from '../../../components/organisms/Shared/Table';
import EmptyState from '../../../components/organisms/Shared/EmptyState';

const BarangMasuk = () => {
  const tableDetails = {
    isNo: false,
    // fields: [
    //   {
    //     title: 'TANGGAL MASUK',
    //     fieldName: 'dateIn',
    //   },
    //   {
    //     title: 'KODE',
    //     fieldName: 'id',
    //   },
    //   {
    //     title: 'NAMA BARANG',
    //     fieldName: 'itemName',
    //   },
    //   {
    //     title: 'JUMLAH',
    //     fieldName: 'itemAmount',
    //   },
    // ],
    data: [],

    actions: {
      isAction: false,
      isDetail: true,
      isEdit: false,
      isDelete: true,
      isPagination: false,
    },

    attributes: {
      isAdd: false,
      isSearch: true,
      isPagination: false,
    },
  };
  return (
    <ContentLayout> 
      <Table tableDetails={tableDetails} itemsPerPage={7} title={'Action'}/>
      <EmptyState />
    </ContentLayout>
  );
};

export default BarangMasuk;
