import React from 'react';
import Img from '../../atoms/Img';
import FormInput from '../../atoms/FormInput';

export default function FormInputIcon({
  srcImg,
  altImg,
  classNameImg,
  eventSetter,
  formInputClassName,
  formInputType,
  formInputPlaceholder,
  formInputRequired,
}) {
  return (
    <div className='flex flex-col text-white py-2 relative'>
      <div className='flex flex-col justify-center'>
        <div className="absolute">
          <Img srcImg={srcImg} altImg={altImg} classNameImg={classNameImg} />
        </div>
        <FormInput
          eventSetter={eventSetter}
          formInputClassName={formInputClassName}
          formInputType={formInputType}
          formInputPlaceholder={formInputPlaceholder}
          formInputRequired={formInputRequired}
        />
      </div>
    </div>
  );
}

FormInputIcon.defaultProps = {
  label: 'Label Input',
  srcImg: './src/assets',
  altImg: 'Icon Input',
  classNameImg: 'w-8 h-8',
  eventSetter: () => {},
  formInputClassName:
    'w-full py-2 pr-10 pl-4 text-gray-700 leading-tight focus:outline-none',
  formInputType: 'text',
  formInputPlaceholder: 'Placeholder Input ...',
  formInputRequired: false,
};
