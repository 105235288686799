import React, { Suspense } from 'react';
import { useLoaderData, useOutlet, Await, Outlet } from 'react-router-dom';

//import component
import LinearProgress from '@material-ui/core/LinearProgress';
import DataGuru from '../../../../pages/teacher/DataGuru';

export default function DataGuruOutlet() {
  const outlet = useOutlet();
  const { teacherData } = useLoaderData();
  return (
    <>
    <Suspense fallback={<LinearProgress />}>
      <Await
        resolve={teacherData}
        children={(teacherData) => (
          <DataGuru teacherData={teacherData}>{outlet}</DataGuru>
        )}
      />
    </Suspense>
    <Outlet />
    </>
  );
}
