export const letters = {
    services: {
      get: {
        getLetterData: () =>
          new Promise((resolve) =>
            setTimeout(async () => {
              try {
                const response = await fetch('https://dummyjson.com/comments');
                const data = await response.json();
                const newData = data.comments.map((item, index) => ({
                  ...item,
                  no: index + 1,
                  user: item.user.username,
                }));
                resolve(newData);
              } catch (error) {
                console.error('Error fetching data:', error);
                resolve([]);
              }
            }, 1000)
          ),
      },
    },
  };
  