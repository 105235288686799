import React, { Suspense } from 'react';
import { useOutlet, Await, useLoaderData } from 'react-router-dom';

//import component
import LinearProgress from '@material-ui/core/LinearProgress';
import WaliKelas from '../../../../pages/teacher/WaliKelas';

export default function WaliKelasOutlet() {
  const outlet = useOutlet();
  const { teacherData } = useLoaderData();
  return (
    <>
    <Suspense fallback={<LinearProgress />}>
      <Await 
        resolve={teacherData}
        children={(teacherData) =>(
          <WaliKelas teacherData={teacherData}>{outlet}</WaliKelas>
        )}
        />
    </Suspense>
    </>
  );
}
