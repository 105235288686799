import React from 'react';
import Img from '../../atoms/Img';
import Label from '../../atoms/Label';
import FormInput from '../../atoms/FormInput';

export default function FormInputLogin({
  label,
  srcImg,
  altImg,
  classNameImg,
  eventSetter,
  formInputClassName,
  formInputType,
  formInputPlaceholder,
  formInputRequired,
}) {
  return (
    <div className='flex flex-col text-white py-2'>
      <Label label={label} />
      <div className='flex flex-col justify-center'>
        <Img srcImg={srcImg} altImg={altImg} classNameImg={classNameImg} />
        <FormInput
          eventSetter={eventSetter}
          formInputClassName={formInputClassName}
          formInputType={formInputType}
          formInputPlaceholder={formInputPlaceholder}
          formInputRequired={formInputRequired}
        />
      </div>
    </div>
  );
}

FormInputLogin.defaultProps = {
  label: 'Label Input',
  srcImg: './src/assets',
  altImg: 'Icon Input',
  classNameImg: '',
  eventSetter: () => {},
  formInputClassName: '',
  formInputType: 'text',
  formInputPlaceholder: 'Placeholder Input ...',
  formInputRequired: false,
};
