import React, { Suspense } from 'react';
import { useLoaderData, useOutlet, Await, Outlet } from 'react-router-dom';

//import component
import LinearProgress from '@material-ui/core/LinearProgress';
import DataSiswa from '../../../../pages/student/DataSiswa';

export default function DataSiswaOutlet() {
  const outlet = useOutlet();
  const { studentData } = useLoaderData();
  return (
    <>
      <Suspense fallback={<LinearProgress />}>
        <Await
          resolve={studentData}
          children={(studentData) => (
            <DataSiswa studentData={studentData}>{outlet}</DataSiswa>
          )}
        />
      </Suspense>
      <Outlet />
    </>
  );
}
