import React from "react";
import {
  defer,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

//importing services
import { auth } from "./services/auth";
import { student } from "./services/student";
import { teacher } from "./services/teacher";

//Page auth components
import FormLogin from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/ForgotPassword";

//Page dashboard components
import Dashboard from "./pages/Dashboard";

//layout components
import AuthLayout from "./layouts/AuthLayout";
import PublicLayout from "./layouts/PublicLayout";
import ProtectedLayout from "./layouts/ProtectedLayout";

//page not found components
import PageNotFound from "./pages/shared/PageNotFound";

//student layout and outlet components
import StudentLayout from "./layouts/StudentLayout";
import DataSiswaLayout from "./layouts/StudentLayout/DataSiswaLayout";
import DataSiswaOutlet from "./layouts/StudentLayout/DataSiswaLayout/Outlets/DataSiswaOutlet";
import DetailSiswaOutlet from "./layouts/StudentLayout/DataSiswaLayout/Outlets/DetailSiswaOutlet"; //--refers to DataSiswaOutlet
import AbsensiSiswaOutlet from "./layouts/StudentLayout/Outlets/AbsensiSiswaOutlet";
import RaportSiswaOutlet from "./layouts/StudentLayout/Outlets/RaportSiswaOutlet";

//teacher layout and outlet components
import TeacherLayout from "./layouts/TeacherLayout";
import DataGuruLayout from "./layouts/TeacherLayout/DataGuruLayout";
import DataGuruOutlet from "./layouts/TeacherLayout/DataGuruLayout/Outlets/DataGuruOutlet";
import DetailGuruOutlet from "./layouts/TeacherLayout/DataGuruLayout/Outlets/DetailGuruOutlet";
import AbsensiGuruOutlet from "./layouts/TeacherLayout/Outlets/AbsensiGuruOutlet";
import WaliKelasOutlet from "./layouts/TeacherLayout/WaliKelasLayout/Outlets/WaliKelasOutlet";
import DetailWaliKelasOutlet from "./layouts/TeacherLayout/WaliKelasLayout/Outlets/DetailWaliKelasOutlet";

//letters layout and outlet components
import LettersLayout from "./layouts/LettersLayout";
import SuratMasukLayout from "./layouts/LettersLayout/SuratMasukLayout";
import SuratMasukOutlet from "./layouts/LettersLayout/SuratMasukLayout/Outlets/SuratMasukOutlet";
// import SuratMasukDetailOutlet --->> buat popup modal saja
import SuratKeluarOutlet from "./layouts/LettersLayout/Outlets/SuratKeluarOutlet";

//inventory layout and outlet components
import InventoryLayout from "./layouts/InventoryLayout";
import BarangMasukOutlet from "./layouts/InventoryLayout/Outlets/BarangMasukOutlet";
import BarangKeluarOutlet from "./layouts/InventoryLayout/Outlets/BarangKeluarOutlet";

//page not found layout components
import PageNotFoundLayout from "./layouts/Shared/PageNotFoundLayout";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        loader={() => defer({ userPromise: auth.services.get.getUserData() })}
        element={<AuthLayout />}
      >
        <Route element={<PageNotFoundLayout />}>
          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route element={<PublicLayout />}>
          <Route path="auth/login" element={<FormLogin />} />
          <Route path="auth/forgot-password" element={<ForgotPassword />} />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route element={<StudentLayout />}>
            <Route element={<DataSiswaLayout />}>
              <Route
                loader={() =>
                  defer({
                    studentData: student.services.get.getStudentData(),
                  })
                }
                path="siswa/data-siswa"
                element={<DataSiswaOutlet />}
              />
              <Route
                path="siswa/data-siswa/detail-siswa"
                element={<DetailSiswaOutlet />}
              />
            </Route>
            <Route
              loader={() =>
                defer({
                  studentData: student.services.get.getStudentData(),
                })
              }
              path="siswa/absensi-siswa"
              element={<AbsensiSiswaOutlet />}
            />
            <Route
              loader={() =>
                defer({
                  studentData: student.services.get.getStudentData(),
                })
              }
              path="siswa/raport-siswa"
              element={<RaportSiswaOutlet />}
            />
          </Route>
          <Route element={<TeacherLayout />}>
            <Route element={<DataGuruLayout />}>
              <Route
                loader={() =>
                  defer({
                    teacherData: teacher.services.get.getTeacherData(),
                  })
                }
                path="guru/data-guru"
                element={<DataGuruOutlet />}
              />
              <Route
                path="guru/data-guru/detail-guru"
                element={<DetailGuruOutlet />}
              />
            </Route>
            <Route
              loader={() =>
                defer({
                  teacherData: teacher.services.get.getTeacherData(),
                })
              }
              path="guru/absensi-guru"
              element={<AbsensiGuruOutlet />}
            />
            <Route
              loader={() =>
                defer({
                  teacherData: teacher.services.get.getTeacherData(),
                })
              }
              path="guru/wali-kelas"
              element={<WaliKelasOutlet />}
            />
            <Route
              path="guru/wali-kelas/detail-wali-kelas"
              element={<DetailWaliKelasOutlet />}
            />
          </Route>
          <Route path="persuratan" element={<LettersLayout />}>
            <Route path="surat-masuk" element={<SuratMasukOutlet />} />
            <Route path="surat-keluar" element={<SuratKeluarOutlet />} />
          </Route>
          <Route path="inventaris" element={<InventoryLayout />}>
            <Route path="barang-masuk" element={<BarangMasukOutlet />} />
            <Route path="barang-keluar" element={<BarangKeluarOutlet />} />
          </Route>
        </Route>
      </Route>
    </>
  )
);

export default router;
