import React from 'react';

export default function Svg({
  classNameSvg,
  fillSvg,
  strokeSvg,
  viewBoxSvg,
  xmlnsSvg,
  strokeLinecapPath,
  strokeLinejoinPath,
  strokeWidthPath,
  dPath,
}) {
  return (
    <>
      <svg
        className={classNameSvg}
        fill={fillSvg}
        stroke={strokeSvg}
        viewBox={viewBoxSvg}
        xmlns={xmlnsSvg}
      >
        <path
          strokeLinecap={strokeLinecapPath}
          strokeLinejoin={strokeLinejoinPath}
          strokeWidth={strokeWidthPath}
          d={dPath}
        />
      </svg>
    </>
  );
}
