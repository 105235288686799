export const sanitizers = {
  //sanitize pathname for get page title
  pageTitleSanitizedFromPathname: (pathname) => {
    let title = '';
    if (pathname.length <= 1) {
      title = 'Dashboard';
    } else {
      title = [];
      let temp = [];
      const lastPathname = pathname.substring(pathname.lastIndexOf('/') + 1);
      const pathParts = lastPathname.split('-');
      for (let i = 0; i <= pathname.split('/').length; i++) {
        const part = pathParts[i];
        if (part) {
          temp.push(
            lastPathname.split('-')[i].charAt(0).toUpperCase() +
              lastPathname.split('-')[i].slice(1)
          );
        }
      }
      title = temp.join(' ');
    }
    return title;
  },
};

export default sanitizers;
