import React from 'react';
import Button from '../../atoms/Button';

export default function Pagination({
  firstIndex,
  lastIndex,
  currentPage,
  setCurrentPage,
  totalPages,
  data,
}) {
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className='flex w-full items-center justify-between px-4 py-1 bg-whit sm:px-6'>
      <div className='hidden sm:flex-1 sm:flex sm:items-center sm:justify-between'>
        <div>
          <p className='text-sm text-gray-700'>
            Showing
            <span className='font-medium'> {data[firstIndex]?.id} </span>
            to
            <span className='font-medium'>
              {' '}
              {data[lastIndex]?.id
                ? data[lastIndex]?.id - 1
                : data?.length}{' '}
            </span>
            of
            <span className='font-medium'> {data?.length} </span>
            results
          </p>
        </div>
        <div>
          <nav
            className='relative z-0 inline-flex rounded-md shadow-sm -space-x-px mx-2'
            aria-label='Pagination'
          >
            <Button
              title={'Prev'}
              className='btn-prev'
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            />
            <span className='relative inline-flex items-center px-4 py-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 mx-1'>
              {currentPage} / {totalPages}
            </span>
            <Button
              title={'Next'}
              className='btn-next'
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            />
          </nav>
        </div>
      </div>
    </div>
  );
}
