import React, { Suspense } from 'react';
import { useLoaderData, useOutlet, Await } from 'react-router-dom';

//import component
import LinearProgress from '@material-ui/core/LinearProgress';

//import hooks
import { AuthProvider } from '../hooks/useAuth';

export default function AuthLayout() {
  const { userPromise } = useLoaderData();
  const outlet = useOutlet();
  return (
    <Suspense fallback={<LinearProgress />}>
      <Await
        resolve={userPromise}
        children={(user) => (
          <AuthProvider userData={user}>{outlet}</AuthProvider>
        )}
      />
    </Suspense>
  );
}
